import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form']

  submit(event) {
    event.preventDefault()
    this.formTarget.submit()
  }

  validateForm(submitEvent) {
    if (this.formTarget.checkValidity()) {
      this.submit(submitEvent)
    } else {
      submitEvent.preventDefault()
      this.formTarget.querySelector('.is-invalid').focus()
    }
  }
}
