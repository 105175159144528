import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['minSelect', 'maxSelect']

  connect() {
    this.minSelectTarget.value = this.minSelectTarget.min
  }

  setMinDate() {
    let target = this.minSelectTarget
    let valid = target.checkValidity()
    target.classList.toggle('is-invalid', !valid)
    if (valid) {
      this.maxSelectTarget.min = target.value
    }
  }

  setMaxDate() {
    let target = this.maxSelectTarget
    let valid = target.checkValidity()
    target.classList.toggle('is-invalid', !valid)
    if (valid) {
      this.minSelectTarget.max = target.value
    }
  }
}
