import { Controller } from 'stimulus'
import { Modal } from 'bootstrap'

// This controller handles modals in conjunction with the
// modal component provided by Bootstrap 5:
// https://getbootstrap.com/docs/5.0/components/modal/
export default class extends Controller {
  static targets = ['container', 'modal']

  connect() {
    this.invoked = this.containerTarget.getAttribute('data-modals-invoked')
    if (this.invoked) {
      this.showModal()
    }
  }

  showModal() {
    this.bsModal = Modal.getOrCreateInstance(this.modalTarget)
    this.bsModal.show()
  }
}
